import React from "react"
import Seo from "../components/seo"

const NotFoundPage = () => (
    <div>
        <Seo title="404: Not found"/>
        <h1>NOT FOUND</h1>
    </div>
)

export default NotFoundPage
